import React from "react"

import Layout from "../../components/Layout"
import RibbonText from "../../components/ui/RibbonText"
import Seo from "../../components/Seo"
import CustomForm from "../../components/form/CustomForm"

export default function Renovation() {
  return (
    <Layout>
      <Seo title="Renovation Questionnaire" />
      <section className="wrapper container py-12">
        <div className="space-y-4">
          <h1>Renovation Questionnaire</h1>
          <RibbonText text="Let's get" highlighted="started" justify="left" />
        </div>
      </section>
      <section className="wrapper px-0">
        <CustomForm
          portalId="5684517"
          formId="bd268b27-e796-4615-a866-4dcdf7a54a97"
          uniqueQuestion="Do you have any renovation or design goals in mind?"
        />
      </section>
    </Layout>
  )
}